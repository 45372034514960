import { Link } from "react-router-dom";

const UserList = ({ users, title }) => {

    return (
        <div className="user-list">
            <div className="header">
                <div className="id">Id</div>
                <div className="trainerName">Trainer Name</div>
                <div className="trainerCode">Trainer Code</div>
                <div className="discordName">Discord Name</div>
                <div className="discordId">Discord Id</div>
                <div className="firstName">First Name</div>
                <div className="lastName">Last Name</div>
                <div className="email">E-mail</div>
                <div className="tier">Tier</div>
            </div>
            {users.map((user) => (
                <Link to={`/users/${user.id}`}>
                    <div className="user-preview" key={user.id}>
                        <div className="id">{user.id}</div>
                        <div className="trainerName">{user.trainer_name}</div>
                        <div className="trainerCode">{user.trainer_code}</div>
                        <div className="discordName">{user.discord_name}</div>
                        <div className="discordId">{user.discord_id}</div>
                        <div className="firstName">{user.first_name}</div>
                        <div className="lastName">{user.last_name}</div>
                        <div className="email">{user.email}</div>
                        <div className="tier">{user.tier}</div>
                    </div>
                </Link>
            ))}
        </div>
    );
}
 
export default UserList;