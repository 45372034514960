import UserList from "./userList";
import useFetch from "./useFetch";

const Users = () => {
    const { data: users, error, IsPending } = useFetch('https://trainerclub.net/api/v2/users/');

    return (
        <div className="users">
            <h2>All Users</h2>
            { error && <div>{error}</div> }            
            { IsPending && <div className="loading">Loading...</div> }
            { users && <UserList users={users} title="All users"/>}
        </div>
    );
}
 
export default Users;