import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo/the_trainer_club.png'

const Navbar = () => {
    return (
        <div className="header">
            <div className="topBar">
                <div className="logo">
                    <img src={Logo} alt="" />
                </div>
                {/* <div className="title">
                    The Trainer Club
                </div> */}
            </div>
            <div className="bottomBar">
                <Link to="/">Home</Link>
                <Link to="/users">Users</Link>
                <Link to="/groups">Groups</Link>
                <Link to="/tiers">Tiers</Link>
                <Link to="/tiers">Messages</Link>
            </div>
        </div>
    );
}
 
export default Navbar;